.mission_vission{
    width: 100%;
    min-height: 15rem;
    background-color: rgb(248, 248, 248);
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.mission_vission .mission,.vision{
    width: 30rem;
    background-color: white;
    padding: 1rem;
    margin: 2rem;
    font-size: 15px;
}

.mission_vission h1{
    font-size: 2rem;
    color: #3b8af2;
}